.searchbar {
    display: flex;
}

.searchbar_input {
    display: flex;
    padding: .5rem;
    border: 2px solid #24456C;
    margin-right: .5rem;
    /* overflow: hidden; */
    position: relative;
}

.searchbar_input img {
    width: 30px;
    margin-right: .5rem;
}

.searchbar_input input {
    outline: none;
}

.searchbar_input span {
    font-size: 1.1rem;
    font-weight: bold;
    color: black;
}

.searchbar_input_btn {
    border-radius: 0 !important;
    height: 50px;
    margin: 0 !important;
    background: #24456C !important;
    border-color: transparent !important;
    width: 50px;
    padding: 10px;
}

.searchbar_input_btn img {
    height: 25px;
    width: 30px;
}

.error_msg {
    position: absolute;
    bottom: -17px;
    font-size: 0.7rem !important;
    color: red !important;
    font-weight: normal !important;
}