.faq__question {
    width: 100%;
    margin-bottom: 0.25rem;
    background-color: #F5F5F5;
}

.faq__question_button {
    width: 100%;
    text-align: start;
    display: flex;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    padding: 20px;
}

.faq__question_button span {
    font-weight: bold;
    font-size: 1.1rem;
}

.faq__question_button::before {
    content: '';
    margin-right: 17px;
    transform: rotate(90deg);
    border-bottom: 17px solid #195ca9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transition: all 0.3s ease;
}

.faq__question_button_active::before {
    transform: rotate(180deg);
}

.faq__desc {
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
}

.faq__desc_data {
    padding: 1rem;
}

.faq__desc_data > span {
    font-size: small;
    margin-right: 1rem;
}

.faq__desc_data_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 1rem;
}

.faq__desc_data_grid_avance {
    display: flex;
    align-items: center;
    position: relative;
}

.faq__desc_data_grid_avance span:nth-child(2) {
    position: absolute;
    right: 0;
    top: -13px;
    font-size: smaller;
}

.faq__desc_data_grid_avance > div {
    height: 7px;
    width: 100%;
    background: #cecece;
    margin-left: 1rem;
}

.faq__desc_data_grid_avance > div > div {
    height: 100%;
    background: #24456C;
}