.container {
    text-align: center;
    margin: auto 0;
    background-color: #edf8fe;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.thead__table {
    background-color: #68b7e2;
    color: black;
    font-weight: bold;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
}

.title {
    font-weight: bold;
    font-size: 1.1rem;
}

.fecha__consultada {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.data__consultada {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.data__consultada_text {
    font-weight: bold;
    font-size: 1rem;
    padding: 6px;
}

.data {
    border: 1px solid black;
    margin-left: 2px;
    margin-right: 4px;
    padding: 6px;
}

.fecha__consultada_text {
    font-weight: bold;
    font-size: 1rem;
    padding: 6px;
}

.fecha {
    border: 1px solid black;
    margin-left: 2px;
    margin-right: 4px;
    padding: 6px;
}

.data__consultada_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table__container {
    margin-top: 20px;
    margin-right: 20px;
}

.fecha__consultada_fideicomiso {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.fecha_consultada_contenedor {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

}

.fecha__consultada_fideicomiso_text {
    font-weight: bold;
    font-size: 1rem;
    border: 1px solid black;
    margin-left: 2px;
    margin-right: 4px;
    padding: 6px;
    background-color: #68b7e2;
    color: black;
}

.fecha_fideicomiso {
    border: 1px solid black;
    margin-left: 2px;
    margin-right: 4px;
    padding: 6px;
}

.div_container_general {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.div_container_general_text {
    display: flex;
}

.th_table {
    width: 20px;
    text-align: center;
    padding: 10px;
}

.th_total{
    width: 180px;
    text-align: center;
    padding: 10px;
}