.kardex {
    width: 85%;
    background: white;
    display: grid;
    grid-template-rows: 200px auto;
    border-radius: 20px;
}

.kardex_header {
    display: grid;
    border-bottom: 2px solid #727171;

    /* cols 3 */

    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    align-items: center;
}

.seccion_reportes{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titulo_seccion {
    font-size: 1.5rem;
    font-weight: bold;
    color: #195ca9;
    margin: 0;
}

.button_generar_kardex{
    width: 200px;
    background-color: #195ca9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    margin: 10px 0 10px 0;
    align-items: baseline;
    font-weight: bold;
}

.select_fideicomiso{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #b5ceff;
    margin: 10px 10px 10px 0px;
    font-weight: bold;
}

.input_user{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #b5ceff;
    margin: 10px 0 10px 0;
    font-weight: bold;
}

.cuerpo{
    text-align: center;
}

.title_cuerpo{
    font-size: 1.5rem;
    font-weight: bold;
    color: #195ca9;
    margin: 0;
    padding: 10px;
}

.header_cuerpo{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    align-items: center;
}

.button__generate_excel{
    width: 200px;
    background-color: #195ca9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    margin: 10px 0 10px 0;
    align-items: baseline;
    font-weight: bold;
}