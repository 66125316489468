.user_detail_header {
    color: white;
    padding: 2rem 30px;
    background: #24456C;
}

.user_detail_header h4 {
    font-size: 2rem;
    font-weight: bold;
}

.user_detail_header_info {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
}

.user_detail_header_info > div {
    display: flex;
    align-items: center;
}

.user_detail_header_info > div img {
    width: 30px;
    height: 25px;
    margin-right: .5rem;
}

.user_detail_info_general {
    padding: 1.5rem 0;
    text-align: center;
    background: #F6F6F6;
}

.user_detail_info_general h5 {
    color: #575756;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.user_detail_info_general_data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content;
}

.user_detail_info_general_data span {
    color: #706F6F;
}

.user_detail_info_general_data h6 {
    color: #24456C;
    font-size: 3rem;
    font-weight: bold;
}

.user_detail_info_cursos {
    margin: 2rem 0;
}

.user_detail_info_cursos h5 {
    color: #575756;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.faq__list {
    width: 100%;
}