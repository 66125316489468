.reporteador {
    width: 85%;
    background: white;
    display: grid;
    grid-template-columns: 350px auto;
}

.reporteador_sidebar {
    background: #e8e8e8;
    overflow: scroll;
}

.reporteador_sidebar_title {
    display: block;
    color: gray;
    font-weight: bold;
    margin-bottom: 1rem;
}

.reporteador_sidebar_nav {
    display: grid;
    grid-template-columns: 50% 50%;
}

.w_full_grid {
    grid-area: 1/3/1/1;
}

.btn_min {
    width: min-content;
    margin: auto;
    grid-area: 1/1/1/3;
    margin-top: 1rem;
    padding: 5px 2rem !important;
    margin-left: auto !important;
}

.reporteador_graphic_header button,
.reporteador_sidebar_nav button,
.reporteador_sidebar_dates input[type=datetime-local],
.reporteador_sidebar_dates select {
    border: 2px solid #b5ceff;
    display: block;
    color: #195ca9;
    font-size: 14px;
    font-weight: 900;
    padding: 0.25rem 0.55rem;
    background-color: #fff;
    border-radius: 1rem;
    margin-left: .5rem;
    margin-bottom: .5rem;
}

.reporteador_sidebar_dates label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin: .5rem 0;
}

.reporteador_sidebar_dates input[type=datetime-local],
.reporteador_sidebar_dates select {
    border-radius: 5px;
    margin: 0;
    outline: none;
    padding: .5rem;
    cursor: pointer;
}

.reporteador_sidebar_nav button:hover {
    color: #fff;
    background: #195ca9;
}

.reporteador_graphic {
    overflow: scroll;
}

.reporteador_graphic_header {
    height: 100px;
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
}

.reporteador_graphic_header > button {
    position: absolute;
    bottom: -4rem;
    right: 0;
}

.reporteador_graphic_header img {
    height: 100%;
}

.reporteador_graphic_sec {
    display: grid;
}

.reporteador_graphic_canv {
    background: white;
}


.active {
    color: #fff !important;
    background-color: #195ca9 !important;
} 

.desabled {
    color: black !important;
    cursor: not-allowed;
    background-color: #bbbbbb !important;
    border-color: transparent !important;
}


.reporteador_table_detail {
    margin: 1rem 0;
}

.reporteador_table_detail h5 {
    margin: 1rem 0;
    font-weight: bold;
}

.reporteador_table_detail table {
    width: 100%;
    background: white;
    border-collapse: collapse;
}
  
.reporteador_table_detail table td, th {
    border: 1px solid #efefef;
    text-align: left;
    padding: 8px;
  }
  
.reporteador_table_detail tr:nth-child(even) {
    background-color: #f9f3f3;
}

.reporteador_table_detail thead {
    color: white;
    text-transform: capitalize;
    background: #24456C;
}

.global_table {
    margin: 1.5rem 0;
}

.global_table h5 {
    font-weight: bold;
}

.global_table > div {
    display: flex;
    justify-content: space-between;
    max-width: 50%;
    background: white;
    padding: 10px;
}

.reporte_filter_aux {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
}

.reporte_filter_aux label {
    margin-right: 1rem;
}

